// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.

//
// Bring in Bootstrap
//

// Toggle global options
$enable-gradients: true;
$enable-shadows: true;
$offcanvas-box-shadow: none;

// values unique to buzzport theme
$gold: #b3a369;
$gold-dark: #a4925a;
$gold-border: #857437;
$navy-gt: #003057;
$blue-gt: #3A5DAE;
$gray-ic: #D6DBD4;
$gray-matter: #54585A;

$buzz-gold: #EAAA00;
$impact-purple: #5F249F;
$electric-blue: #64CCC9;
$rat-cap: #FFCD00;
$canopy-lime: #A4D233;
$new-horizon: #E04F39;
$olympic-teal: #008C95;

$bright-purple: #7800FF;
$bright-electric: #00FFFF;
$bright-buzz: #FFCC00;
$bright-blue: #2961FF;
$bright-canopy: #00EC9C;
$bright-horizon: #FF640F;

$blue: darken($bright-blue, 5%);
$blue-darkmode: #6ea8fe;
$gray: $gray-ic;

$white: #fff;
$gray-100: lighten($gray, 10%);
$gray-200: $gray;
$gray-300: darken($gray, 12.5%);
$gray-400: darken($gray, 25%);
$gray-500: darken($gray, 37.5%);
$gray-600: darken($gray, 50%);
$gray-700: darken($gray, 62.5%);
$gray-800: darken($gray, 75%);
$gray-900: darken($gray, 87.5%);
$black: #000;

$indigo: $bright-purple;
$purple: $impact-purple;
$pink: #e3008c;
$red: $new-horizon;
$orange: $bright-buzz;
$yellow: $rat-cap;
$green: $canopy-lime;
$teal: $electric-blue;
$cyan: #9954BB;

$primary: $blue;
$secondary: $gray-800;
$success: $green;
$info: $cyan;
$danger: $red;
$light: $gray-100;
$dark: $black;
$dark-text-emphasis: $white;
$dark-bg-subtle: $gray-800;
$navbar-dark-hover-color: rgba($white, 1);
$enable-rounded: false;
$border-radius-base: 0;
$body-bg: $gray-100;
$body-color: $black;
$navbar-light-toggler-border-color: rgba($black, .3);
$border-color: $gray-300;
$card-border-color: $gray-300;
$pagination-border-color: $gray-400;
$list-group-border-color: $gray-400;
$breadcrumb-divider-color: $gray-800;
$breadcrumb-active-color: $gray-800;

$link-color: $blue;
$link-hover-color: darken($blue, 15%);

$link-color-darkmode: $blue-darkmode;
$link-hover-color-darkmode: lighten($link-color-darkmode, 15%);

$gradient: none;
$color-mode-type: data;

h1.title {
  font-family: 'Roboto Condensed', 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

header.navbar-main {
  color: $black;
  background-color: $gold;
}
header.navbar-main a.nav-link,
header.navbar-main a.nav-link:focus,
header.navbar-main a.nav-link:hover {
  color: $black;
}
header.navbar-main a.nav-link:focus,
header.navbar-main a.nav-link:hover {
  text-decoration: underline;
}

.sidebar .dropdown-menu {
  background-color: transparent;
  box-shadow: none;
}
.sidebar nav .dropdown-menu.show a.dropdown-item:focus,
.sidebar nav .dropdown-menu.show a.dropdown-item:hover {
  background-color: transparent;
}

.card-header {
  font-family: 'Roboto Condensed', 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 700;
}

// Option 1
// Import all of Bootstrap's CSS

@import "bootstrap/scss/bootstrap";


@include color-mode(light) {
  body {
    background-color: $gray-100;
    color: $gray-900;
  }

  h1.title {
    color: $gray-matter;
    font-family: 'Abel', 'Roboto Condensed', 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 700;
  }

  .sidebar {
    background-color: lighten($gray-200, 5%);
  }

  .title.border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) $gray-300 !important;
  }

  .nav-pills a.nav-link.active,
  button.nav-link.active {
    background-color: $dark;
  }
  .nav-pills a.nav-link,
  button.nav-link {
    background-color: lighten($gray-200, 2.5%);
  }
  .nav-pills .nav-link:not(.active):hover {
    background-color: $primary;
    color: $white;
  }

  .text-muted {
    color: $gray-matter !important;
  }

  .card-header {
    background-color: lighten($gray-200, 5%);
    color: $gray-900;
  }
  .card-body {
    color: $gray-900;
    background-color: $white;
  }

  .card a .fad {
    --fa-secondary-color: #c0c5be;
    --fa-secondary-opacity: 1;
  }
  .card a:hover .fad {
    --fa-secondary-color: #2961FF;
    --fa-secondary-opacity: .4;
  }

  .navbar-toggler:hover {
    background-color: $gold-dark;
  }
}

@include color-mode(dark) {
  body {
    color: $gray-100;
    background-color: $gray-800;
  }

  h1.title {
    color: $gold;
  }

  .sidebar {
    background-color: $gray-900;
  }

  .sidebar nav .dropdown-menu.show a.dropdown-item {
    color: $link-color-darkmode;
  }
  .sidebar nav .dropdown-menu.show a.dropdown-item:focus,
  .sidebar nav .dropdown-menu.show a.dropdown-item:hover {
    background-color: transparent;
    color: $link-hover-color-darkmode;
    text-decoration: none;
  }

  .title.border-bottom {
    border-bottom: var(--bs-border-width) var(--bs-border-style) $gray-600 !important;
  }

  .nav-pills a.nav-link.active,
  button.nav-link.active {
    background-color: $primary;
  }
  .nav-pills a.nav-link,
  button.nav-link {
    background-color: $gray-700;
  }

  .nav-pills .nav-link {
    color: $white;
  }
  .nav-pills .nav-link.active {
    color: $black;
    background-color: $link-color-darkmode;
  }
  .nav-pills .nav-link:not(.active):focus,
  .nav-pills .nav-link:not(.active):hover {
    color: $link-hover-color-darkmode;
    text-decoration: underline;
  }

  .text-muted {
    color: $gray-300 !important;
  }

  .card {
    border: var(--bs-card-border-width) solid $gray-600;
  }

  .card-header {
    color: $gray-100;
    background-color: $gray-900;
    border-bottom: var(--bs-card-border-width) solid $gray-600;
  }

  .card-body {
    color: $gray-100;
    background-color: $gray-900;
  }
  .card-body a {
    color: $link-color-darkmode;
  }
  .card-body a:focus,
  .card-body a:hover {
    color: $link-hover-color-darkmode;
  }

  .card a .fad {
    --fa-secondary-color: #6ea8fe;
    --fa-secondary-opacity: .6;
  }
  .card a:hover .fad {
    --fa-secondary-color: #6ea8fe;
    --fa-secondary-opacity: .9;
  }

  .navbar-toggler:hover {
    background-color: $gold-dark;
  }

  .table {
    --bs-table-color: $white;
    --bs-table-striped-color: $white;
    --bs-table-active-color: $white;
    --bs-table-hover-color: $white;
  }

  .btn-outline-primary:not(:hover),
  .btn-outline-secondary:not(:hover),
  .btn-outline-success:not(:hover),
  .btn-outline-danger:not(:hover),
  .btn-outline-warning:not(:hover),
  .btn-outline-info:not(:hover),
  .btn-outline-dark:not(:hover),
  .btn-outline-light:not(:hover) {
    background-color: $gray-900;
  }
  .btn-outline-primary {
    --bs-btn-color: #6ea8fe;
    --bs-btn-border-color: #6ea8fe;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #6ea8fe;
    --bs-btn-hover-border-color: #6ea8fe;
    --bs-btn-focus-shadow-rgb: 16, 78, 255;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #6ea8fe;
    --bs-btn-active-border-color: #6ea8fe;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #6ea8fe;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #6ea8fe;
    --bs-gradient: none;
  }

  .page-link {
    --bs-pagination-hover-color: #fff;
    --bs-pagination-hover-bg: #6ea8fe;
    --bs-pagination-hover-border-color: #6ea8fe;
  }

  .breadcrumb-item a {
    color: $link-color-darkmode;
  }
  .breadcrumb-item a:focus,
  .breadcrumb-item a:hover {
    color: $link-hover-color-darkmode;
  }
  .breadcrumb-item.active {
    color: $gray-300;
  }
  .breadcrumb-item+.breadcrumb-item::before {
    color: $gray-300;
  }
}

//
// Custom styles
//

@import "icon-list";

body {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

table th a[sort="asc"]:after {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  content: "\f0d8";
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
table th a[sort="desc"]:after {
  display: inline-block;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  content: "\f0d7";
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

a {
  text-decoration: none;
}

.sidebar a:hover {
  text-decoration: underline;
}
.sidebar nav {
  width: 100%;
}
.sidebar nav .dropdown.nav-item:has(.dropdown-menu.show) {
  font-weight: 700;
}
.sidebar nav .dropdown-menu.show {
  border: 0 solid transparent;
  display: block;
  position: relative !important;
  transform: none !important;
  width: 100%;
}
.sidebar nav .dropdown-menu.show a.dropdown-item {
  display: block;
  padding-left: 2rem;
  width: 100%;
}
@include media-breakpoint-down(md) {
  .sidebar.border-end {
    border-right: 0px solid transparent !important;
    border-bottom: 1px solid $border-color !important;
  }
}

.icon-link {
  display: block;
}
@include media-breakpoint-down(lg) {
  .icon-link {
    display: inline-flex;
  }
}
@include media-breakpoint-down(sm) {
  .icon-link {
    display: block;
  }
}

.scroll-top {
  position: fixed;
  height: 3.5rem;
  width: 3.5rem;
  bottom: 3.5rem;
  right: 1.5rem;
  z-index: 99;
  outline: none;
  color: $white;
  background-color: rgba($primary, .55);
  border: 0px solid transparent;
  cursor: pointer;
  padding: .65rem 0;
  border-radius: 0;
  margin: 0 auto;
}
.scroll-top:focus,
.scroll-top:hover {
  background-color: rgba($primary, 1);
}

@include color-mode(dark) {
  .scroll-top {
    color: $black;
    background-color: rgba($gold, .85);
  }
  .scroll-top:focus,
  .scroll-top:hover {
    background-color: rgba($gold, 1);
  }
}

.is-visible {
  display: block;
}
.is-hidden {
  display: none;
}

.bd-mode-toggle {
  position: fixed;
  height: 2rem;
  width: 3.5rem;
  bottom: 1rem;
  right: 1.5rem;
  z-index: 98;
  outline: none;
  margin: 0 auto;
}
.bd-mode-toggle .btn {
  width: 100%;
  color: rgba($primary, 1);
  background-color: transparent;
  border: 0px solid transparent;
  cursor: pointer;
  border-radius: 0;
  box-shadow: none;
}
.bd-mode-toggle .btn:hover {
  color: $white;
  background-color: rgba($primary, .85);
}
.bd-mode-toggle button.dropdown-toggle svg use {
  fill: rgba($primary, 1);
}
.bd-mode-toggle button.dropdown-toggle:hover svg use {
  fill: $white;
}
.bd-mode-toggle button.dropdown-item svg use {
  fill: $gray-800;
}
.bd-mode-toggle button.dropdown-item.active {
  background-color: rgba($gray-200, 1);
  color: $gray-800;
}
.bd-mode-toggle button.dropdown-item:hover {
  background-color: rgba($gray-300, 1);
  color: $black;
}
.bd-mode-toggle button.dropdown-item.active svg use {
  fill: $gray-800;
}
.bd-mode-toggle button.dropdown-item:hover svg use {
  fill: $black;
}

@include color-mode(dark) {
  .bd-mode-toggle .btn {
    color: rgba($gold, 1);
  }
  .bd-mode-toggle .btn:hover {
    color: $black;
    background-color: rgba($gold, .85);
  }
  .bd-mode-toggle button.dropdown-toggle svg use {
    fill: rgba($gold, 1);
  }
  .bd-mode-toggle button.dropdown-toggle:hover svg use {
    fill: $black;
  }
  .bd-mode-toggle button.dropdown-item svg use {
    fill: $white;
  }
  .bd-mode-toggle button.dropdown-item.active {
    background-color: rgba($gray-800, 1);
    color: $white;
  }
  .bd-mode-toggle button.dropdown-item.active svg use {
    fill: $white;
  }
  .bd-mode-toggle button.dropdown-item:hover {
    background-color: rgba($gray-700, 1);
    color: $white;
  }
  .bd-mode-toggle button.dropdown-item:hover svg use {
    fill: $white;
  }
}

#navbar-main {
  height: 5rem;
}
#navbar-logo-area {
  height: 5rem;
  display: block;
  background-color: $gold-dark;
  background-image: url("../img/logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center, 50%, 50%;
}
#navbar-logo-area.border-end {
  border-right: 1px solid $gold-border !important;
}
@include media-breakpoint-down(md) {
  #navbar-main,
  #navbar-logo-area {
    height: 4rem;
  }
}
@include media-breakpoint-down(sm) {
  #navbar-main {
    height: 8rem;
  }
  #navbar-logo-area {
    height: 4rem;
    background-size: contain;
  }
  #navbar-logo-area.border-end {
    border-right: 0px solid transparent !important;
  }
  #navbar-logo-area.border-bottom {
    border-bottom: 1px solid $gold-border !important;
  }
  #navbar-site-name {
    height: 4rem;
  }
}

#navbar-site-name {
  color: $black;
  font-family: 'Abel', 'Roboto Condensed', 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

#accent-logo {
  height: 2rem;
  display: block;
  background-color: transparent;
  background-image: url("../img/logo2.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center, 50%, 50%;
  margin-bottom: 1rem;
}

@include color-mode(dark) {
  #navbar-logo-area {
    background-image: url("../img/logo-dark.svg");
  }
  #accent-logo {
    background-image: url("../img/logo2-dark.svg");
  }
}

.navbar-toggler .navbar-toggler-icon {
  background-image: url("../img/xmark.svg");
}

.navbar-toggler.collapsed .navbar-toggler-icon {
  background-image: url("../img/hamburger.svg");
}

.bg-warning {
  --bs-bg-opacity: .5;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

#accent-footer {
  background: $gold-dark;
  background: linear-gradient(to right, $gold-dark 0%, $gold-dark 66%, $gold 80%, $gold 100%);
  background: -moz-linear-gradient(left, $gold-dark 0%, $gold-dark 66%, $gold 80%, $gold 100%);
  background: -webkit-linear-gradient(left, $gold-dark 0%, $gold-dark 66%, $gold 80%, $gold 100%);
}

#org-footer {
  background: $gold;
  background: linear-gradient(to right, $gold 0%, $gold 66%, $gold-dark 80%, $gold-dark 100%);
  background: -moz-linear-gradient(left, $gold 0%, $gold 66%, $gold-dark 80%, $gold-dark 100%);
  background: -webkit-linear-gradient(left, $gold 0%, $gold 66%, $gold-dark 80%, $gold-dark 100%);
}

#org-footer address,
#org-footer a,
#org-footer p {
  color: $gray-900;
  font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
  font-size: .85rem;
  font-weight: 300;
  line-height: 1.1;
}

#org-footer address {
  line-height: 1.6;
}

#org-footer address strong {
  font-weight: 700;
}

#org-footer a,
#org-footer a:visited,
#org-footer a:hover,
#org-footer a:focus {
  color: $gray-900;
}

#org-footer a {
  text-decoration: none;
}

#org-footer a:hover {
  text-decoration: underline;
}

#org-footer .left-bordered {
  border-left: 1px solid $white;
  padding-left: 1rem;
}

#org-footer .copyr {
  color: $black;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: normal !important;
  text-align: center;
}

@include media-breakpoint-down(sm) {
  #accent-logo {
    margin-top: 2rem;
  }
  #accent-footer {
    background: $gold-dark;
    background: linear-gradient(to bottom, $gold-dark 0%, $gold-dark 100%);
    background: -moz-linear-gradient(left, $gold-dark 0%, $gold-dark 100%);
    background: -webkit-linear-gradient(left, $gold-dark 0%, $gold-dark 100%);
  }
  #org-footer {
    background: $gold;
    background: linear-gradient(to bottom, $gold 0%, $gold 66%, $gold-dark 80%, $gold-dark 100%);
    background: -moz-linear-gradient(top, $gold 0%, $gold 66%, $gold-dark 80%, $gold-dark 100%);
    background: -webkit-linear-gradient(top, $gold 0%, $gold 66%, $gold-dark 80%, $gold-dark 100%);
  }
}

.sidebar nav .dropdown-menu.show a.dropdown-item.disabled,
.icon-link.disabled {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  font-style: italic;
  opacity: .6;
}
.sidebar nav .dropdown-menu.show a.dropdown-item.disabled:after,
.icon-link.disabled:after {
  content: "(Not Available)";
  display: block;
  opacity: .8;
}
@include color-mode(light) {
  .sidebar nav .dropdown-menu.show a.dropdown-item.disabled,
  .sidebar nav .dropdown-menu.show a.dropdown-item.disabled:after,
  .icon-link.disabled,
  .icon-link.disabled:after {
    color: $black;
  }
}
@include color-mode(dark) {
  .sidebar nav .dropdown-menu.show a.dropdown-item.disabled,
  .sidebar nav .dropdown-menu.show a.dropdown-item.disabled:after,
  .icon-link.disabled,
  .icon-link.disabled:after {
    color: $white;
  }
}
